*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

.scanner-container {
  z-index: 9999;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scanCanvas {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.overlay {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.stop-scanning-cta {
  align-self: flex-end;
  margin-bottom: 3rem;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-color: red;
  text-align: center;
  border: none;
  color: white;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}